<template>
  <div>
    <h5 class="is-size-5 has-text-centered">
      {{ doc.name }}
    </h5>

    <a-input
      field="name"
      type="text"
    />

    <a-input
      field="email"
      type="text"
      disabled
    />

    <a-input
      field="phone"
      type="text"
    />

    <a-select
      field="role"
      null-option
      @change="changeRole"
    />

    <a-select
      v-if="doc.role === 'customer'"
      field="customer"
      options="customers"
    />
  </div>
</template>

<script>
import formMixin from './formMixin'

export default {
  mixins: [formMixin],
  methods: {
    changeRole(role) {
      if (role !== 'customer') {
        this.$store.dispatch('currentDoc/set', {
          field: 'customer',
          value: null
        })
      }
    }
  }
}
</script>
